import styled from 'styled-components';

interface PropsPerguntaAberta {
  error: boolean;
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-x: hidden;
  max-width: 100%;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray400};
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TextArea = styled.textarea<PropsPerguntaAberta>`
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.red : theme.colors.gray200};
  border-radius: 4px;
  padding: 16px 18px 16px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  font-style: normal;
  color: ${({ theme }) => theme.colors.gray500};
  max-width: 100%;
  width: 250px;
  resize: none;
  height: 48px;
  overflow: hidden;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray300};
  }

  @media only screen and (min-width: 426px) {
    font-size: 16px;
    line-height: normal;
  }
`;

export const ClearTextArea = styled.button`
  color: ${({ theme }) => theme.colors.gray400};
  position: absolute;
  right: 5px;
  top: 3px;
`;

export const SpanError = styled.span`
  font: normal 400 12px/12px 'Open Sans';
  color: ${({ theme }) => theme.colors.statusError};
  margin-top: 8px;
`;

export const TextAreaContainer = styled.div`
  max-width: 100%;
  width: fit-content;
  position: relative;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
`;

export const InputErrorMsg = styled.span`
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.statusError};
  margin-top: 8px;
`;
