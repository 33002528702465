import { MutableRefObject, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface IProps {
  minWidth: string;
  minHeight: string;
  padding?: string;
}

const BindContent = styled.div<IProps>`
  width: max-content;
  word-wrap: break-word;
  padding: ${({ padding }) => padding || 0};
  min-height: ${({ minHeight }) => minHeight || 0};
  min-width: ${({ minWidth }) => minWidth || 0};
  visibility: hidden;
  position: absolute;
  font-size: 14px;
  line-height: 21px;

  @media only screen and (min-width: 426px) {
    font-size: 16px;
    line-height: normal;
  }
`;

export const useResizableTextArea = (
  textAreaRef: MutableRefObject<HTMLTextAreaElement>, 
  value: string, 
  containerRef: MutableRefObject<HTMLElement>,
  checkboxWidth = 0
) => {
  const bindRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (bindRef.current && containerRef.current) {
      bindRef.current.style.maxWidth = `${containerRef.current.clientWidth - checkboxWidth}px`;
    }
  }, [containerRef.current]);

  useEffect(() => {
    if (bindRef.current) {
      textAreaRef.current.style.width = `${bindRef.current.clientWidth}px`;
      textAreaRef.current.style.height = `${bindRef.current.clientHeight}px`;
    }
  }, [value]);
    
  const render = (props: IProps): JSX.Element => {
    return (
      <BindContent 
        ref={bindRef}
        {...props}
      >
        { value }
      </BindContent>
    );
  };

  return [render];
};
