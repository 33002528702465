import { Check } from 'components/Icons/Check';
import { Clip } from 'components/Icons/Clip';
import { Reload } from 'components/Icons/Reload';
import {
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { InputTitle } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  preview: File | null;
  setPreview: Dispatch<SetStateAction<File | null>>;
  video?: boolean;
}

const InputFile = ({
  name,
  preview,
  setPreview,
  video = false,
  ...rest
}: InputProps) => {
  const [progress, setProgress] = useState(preview ? 'uploaded' : 'uploadFile');
  useEffect(() => {
    if (progress === 'uploading' && preview !== null) {
      setProgress('uploaded');
    }
    if (preview === null) setProgress('uploadFile');
  }, [preview, progress]);

  useEffect(() => {
    if (preview instanceof File) {
      setProgress('uploaded');
    }
  }, [preview]);

  const inputContent = () => {
    switch (progress) {
      case 'uploadFile':
        return (
          <div className="py-5 px-8 gap-12 bg-contrasteTensaiRoxo flex cursor-pointer items-center h-14 w-full rounded-md relative">
            <input
              type="file"
              className="absolute top-0 left-0 w-full bg-gray-500 h-14 opacity-0 cursor-pointer"
              id={name}
              name={name}
              onChange={event => {
                setProgress('uploading');
                if (!event.target.files) setProgress('error');
                if (event.target.files) setPreview(event.target.files[0]);
                if (rest.onChange) rest.onChange(event);
              }}
              {...rest}
            />
            <Clip className="cursor-pointer" />
            <h2 className="text-white font-roboto normal font-normal mr-7">
              {video ? 'Anexar um vídeo' : 'Anexar uma foto'}
            </h2>
          </div>
        );
      case 'uploading':
        return (
          <InputTitle className="text-detalheTensai font-roboto normal font-normal mr-7">
            Carregando...
          </InputTitle>
        );
      case 'uploaded':
        return (
          <div className="flex gap-3 items-center">
            <div className="py-5 px-8 gap-12 bg-transparent border border-contrasteTensai flex items-center h-14 w-full rounded-md relative">
              <Check className="flex" />
              <InputTitle>Upload feito com sucesso</InputTitle>
            </div>
            <div
              className="h-14 w-14 flex items-center justify-center bg-contrasteTensai rounded-md cursor-pointer"
              onClick={() => {
                setPreview(null);
              }}
            >
              <Reload className="flex" />
            </div>
          </div>
        );
      default:
        return (
          <div className="flex gap-3 items-center">
            <div className="py-5 px-8 gap-12 bg-transparent border border-alert flex items-center h-14 w-full rounded-md relative">
              <InputTitle className="text-detalheTensai font-roboto normal font-normal mr-7">
                Erro no upload, tente novamente
              </InputTitle>
            </div>
            <div
              className="h-14 w-14 flex items-center justify-center bg-alert rounded-md cursor-pointer"
              onClick={() => {
                setPreview(null);
              }}
            >
              <Reload className="flex" />
            </div>
          </div>
        );
    }
  };
  return <div className="flex flex-col gap-5">{inputContent()}</div>;
};

export default InputFile;
