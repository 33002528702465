import { Button } from 'components';
import { Pergunta } from 'core/pesquisa/Pesquisa';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../index.css';
import { genericRespostaAtual, PerguntaRef } from '../interfaces';
import {
  StyledClockIcon,
  StyledLabel,
  TimeInput,
  TimeInputContainer,
  TimePickerContainer,
  TimeSelect,
  TimeSelector,
  TimeSelectorContainer,
  TimeSelectorWrapper,
  TimeSpan,
  TimeWrapper,
} from './styles';
import {
  formatHorarioToTwoDigits,
  formatHourToLabel,
  formatMinuteToLabel,
  getFullHorario,
  handleCompleteHour,
  isValidFullHorario,
  isValidHour,
  isValidMinute,
  replaceBadInputs,
} from './utils';

interface Props {
  pergunta: Pergunta;
  respostaAtual: (id: number) => genericRespostaAtual;
}

enum ListTypes {
  HOUR = 'hour',
  MINUTE = 'minute',
}

const hourOptionsList: Array<string> = Array(24)
  .fill(0)
  .map((_, i) => String(i));

const minutesOptionsList: Array<string> = Array(60)
  .fill(0)
  .map((_, i) => String(i));

const PerguntaHorarioReferenciable: ForwardRefRenderFunction<
  PerguntaRef,
  Props
> = (props, ref) => {
  const { pergunta, respostaAtual } = props;
  const [horario, setHorario] = useState<string>('');
  const [horarioTemporario, setHorarioTemporario] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [timePickerIsVisible, setTimePickerIsVisible] = useState(false);
  const [hourSelectorIsVisible, setHourSelectorIsVisible] = useState(false);
  const [minuteSelectorIsVisible, setMinuteSelectorIsVisible] = useState(false);

  const dateInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    alternativas: null,
    data: null,
    texto: null,
    foto: null,
    destinoPulo: null,
    alternativas_selecionadas: null,
    horario: horario.length !== 0 ? horario : null,
    vazia: horario === '' || !isValidFullHorario(horario),
  }));

  // Consome o estado local armazenado na ultima ação de próximo/voltar
  // em uma pergunta
  useEffect(() => {
    const respostaLocalAtual = respostaAtual(pergunta.id);
    if (
      respostaLocalAtual &&
      typeof respostaLocalAtual.estadoAtual === 'string'
    ) {
      const novoHorario =
        respostaLocalAtual.estadoAtual.split(':').length > 2
          ? respostaLocalAtual.estadoAtual.slice(0, 5)
          : respostaLocalAtual.estadoAtual;
      setHorario(novoHorario);
      setHorarioTemporario(novoHorario);
    }
  }, [pergunta.id, respostaAtual]);

  /**
   * Effects: Lida com a detecção de quando o componente
   * TimePickerSelector deve ser fechado
   *
   * gancho: Ao clicar fora do container principal da pergunta
   */
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        (!containerRef.current.contains(event.target) ||
          containerRef.current === event.target)
      ) {
        if (timePickerIsVisible) {
          setTimePickerIsVisible(false);
          setHourSelectorIsVisible(false);
          setMinuteSelectorIsVisible(false);
        }
      }
    }

    // Adiciona o evento de clique global
    document.addEventListener('mousedown', handleClickOutside);

    // Remove o evento de clique global quando o componente é desmontado
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, timePickerIsVisible]);

  /**
   * Callbacks: Atualiza os valores dos componentes TimeSelector
   * de acordo com o valor do input de horário principal (TimeInput)
   */
  const handleAutoSetSelectorDate = useCallback(() => {
    if (isValidFullHorario(horarioTemporario)) {
      const [hour, minutes] = horarioTemporario.split(':');

      setSelectedHour(hour);
      setSelectedMinute(minutes);
    } else {
      setSelectedHour('');
      setSelectedMinute('');
    }
  }, [horarioTemporario]);

  /**
   * Callbacks: Lida com a mudança de visibilidade do componente
   * TimePickerContainer
   */
  const handleOpenTimePicker = useCallback(
    event => {
      if (dateInputRef.current) {
        dateInputRef.current.focus();
      }

      setTimePickerIsVisible(state => !state);
      handleAutoSetSelectorDate();
    },
    [timePickerIsVisible, horario],
  );

  /**
   * Lida com a visibilidade dos seletores de tempo, tanto para
   * os seletores de hora quanto os seletores de minuto.
   * @param inputType Input que foi acionado, pode ser o input
   * que armazena a hora ou input que armazena o minuto.
   */
  const handleOpenSelector = function (inputType: string) {
    if (inputType === ListTypes.HOUR) {
      setHourSelectorIsVisible(true);
    } else {
      setMinuteSelectorIsVisible(true);
    }
  };

  /**
   * Callbacks: Evento que será disparado ao selecionar
   * uma opção da listagem de horas ou minutos.
   *
   * value: valor correspondente da opção
   * inputType: Indica qual o seletor (horas | minutos).
   */
  const handleOnSelectOption = useCallback(
    (value: string, inputType: string) => {
      const toTwoDigits = formatHorarioToTwoDigits(value);

      if (inputType === ListTypes.HOUR) {
        setSelectedHour(toTwoDigits);

        window.setTimeout(() => {
          setHourSelectorIsVisible(false);
        }, 10);
      } else {
        setSelectedMinute(toTwoDigits);

        window.setTimeout(() => {
          setMinuteSelectorIsVisible(false);
        }, 10);
      }
    },
    [],
  );

  /**
   * Callbacks: Retorna a listagem de opções dos seletores de horas
   * ou minutos.
   */
  const renderTimeSelectorOptions = useCallback(
    (list: Array<string>, inputType: string) => {
      return list.map((value, index) => (
        <option
          value={value}
          key={`option-${index + 1}`}
          onMouseDown={event => {
            event.preventDefault();
            handleOnSelectOption(value, inputType);
          }}
        >
          {inputType === ListTypes.HOUR
            ? formatHourToLabel(value)
            : formatMinuteToLabel(value)}
        </option>
      ));
    },
    [],
  );

  /**
   * Lida com a renderização do componente de seletor de horas ou minutos.
   */
  const renderTimeSelector = useCallback(
    (inputType: string) => {
      if (inputType === ListTypes.HOUR && hourSelectorIsVisible) {
        return (
          <TimeSelect>
            {renderTimeSelectorOptions(hourOptionsList, ListTypes.HOUR)}
          </TimeSelect>
        );
      }

      if (inputType === 'minute' && minuteSelectorIsVisible) {
        return (
          <TimeSelect>
            {renderTimeSelectorOptions(minutesOptionsList, ListTypes.MINUTE)}
          </TimeSelect>
        );
      }

      return null;
    },
    [hourSelectorIsVisible, minuteSelectorIsVisible],
  );

  /**
   * Auxiliar simples para lidar com o reset dos valores de estado
   * de hora ou minuto.
   * @param hour Indica se deve resetar o estado de hora
   */
  const resetHourOrMinutesProxy = function (hour: boolean) {
    if (hour) {
      setSelectedHour('');
    } else {
      setSelectedMinute('');
    }
  };

  /**
   * Lida com o evento de blur do seletor do tipo hora, basicamente
   * irá resetar o valor do campo caso o usuário não tenha feito
   * uma entrada válida.
   * @param event Evento DOM correspondente ao "onBlur" do input de hora
   */
  const handleHourSelectorBlur = function () {
    if (hourSelectorIsVisible) {
      setHourSelectorIsVisible(false);
    }

    if (!isValidHour(selectedHour)) {
      resetHourOrMinutesProxy(true);
    }
  };

  /**
   * Lida com o evento de blur do seletor do tipo minuto, basicamente
   * irá resetar o valor do campo caso o usuário não tenha feito
   * uma entrada válida.
   * @param event Evento DOM correspondente ao "onBlur" do input de minuto
   */
  const handleMinuteSelectorBlur = function () {
    if (minuteSelectorIsVisible) {
      setMinuteSelectorIsVisible(false);
    }

    if (!isValidMinute(selectedMinute)) {
      resetHourOrMinutesProxy(false);
    }
  };

  /**
   * Lida com o blur do componente de input do horário principal
   * (TimeInput), basicamente irá verificar se o input do usuário
   * é um horario válido e atualizar os estados de horário caso seja,
   * e caso não seja, limpar o input.
   */
  const handleDateInputBlur = function () {
    const completedHour = handleCompleteHour(horarioTemporario);
    setHorarioTemporario(completedHour);

    if (isValidFullHorario(completedHour)) {
      const finalTime = formatHorarioToTwoDigits(completedHour);
      setHorario(finalTime);

      if (finalTime !== completedHour) {
        setHorarioTemporario(finalTime);
      }
    } else {
      setHorarioTemporario('');

      if (horario) {
        setHorario('');
      }
    }

    if (timePickerIsVisible) {
      handleAutoSetSelectorDate();
    }
  };

  const handleDateInputChange = useCallback(event => {
    let currentHour = event.target.value as string;
    let lastLength: number;

    do {
      lastLength = currentHour.length;
      currentHour = replaceBadInputs(currentHour);
    } while (currentHour.length > 0 && lastLength !== currentHour.length);

    setHorarioTemporario(currentHour);
  }, []);

  const handleChangeHourSelectorValue = useCallback(event => {
    setSelectedHour(event.target.value);
  }, []);

  const handleChangeMinuteSelectorValue = useCallback(event => {
    setSelectedMinute(event.target.value);
  }, []);

  const handleClear = useCallback(() => {
    setSelectedHour('');
    setSelectedMinute('');
    setHorario('');
    setHorarioTemporario('');
  }, []);

  /**
   * Completa o horário caso o usuario selecione somente um dos valores
   * (horas ou minutos)
   */
  const completeHourAndMinute = useCallback(() => {
    let hour;
    let minutes;

    if (selectedHour.length || selectedMinute.length) {
      minutes = !selectedMinute.length ? '00' : selectedMinute;
      hour = !selectedHour.length ? '00' : selectedHour;
    }

    return { hour, minutes };
  }, [selectedHour, selectedMinute]);

  const handleConfirmChangeHourAndMinute = useCallback(() => {
    const { hour, minutes } = completeHourAndMinute();

    if (isValidHour(hour) && isValidMinute(minutes)) {
      const fullHorario = getFullHorario(hour, minutes);

      if (fullHorario !== horario) {
        setHorarioTemporario(fullHorario);
        setHorario(fullHorario);
      }
    }

    // close time picker
    setTimePickerIsVisible(false);
  }, [selectedHour, selectedMinute, horario]);

  return (
    <TimeWrapper ref={containerRef}>
      <StyledLabel htmlFor="time-input">Insira sua resposta aqui</StyledLabel>
      <TimeInputContainer>
        <TimeInput
          id='time-input'
          type='text'
          inputMode="numeric"
          ref={dateInputRef}
          onChange={handleDateInputChange}
          onBlur={() => handleDateInputBlur()}
          value={horarioTemporario}
          placeholder='00:00'
        />
        <div className="clock-icon-wrapper" onClick={handleOpenTimePicker}>
          <StyledClockIcon className="clock-icon" />
        </div>
      </TimeInputContainer>
      {timePickerIsVisible ? (
        <TimePickerContainer>
          <div className="datepicker-container-header">
            Selecione o horário
          </div>
          <TimeSelectorContainer>
            <TimeSelectorWrapper>
              <TimeSpan>Hora</TimeSpan>
              <TimeSelector
                type='text'
                inputMode="numeric"
                onChange={handleChangeHourSelectorValue}
                onClick={() => handleOpenSelector(ListTypes.HOUR)}
                onBlur={() => handleHourSelectorBlur()}
                value={selectedHour}
                placeholder='00'
              />
              {renderTimeSelector(ListTypes.HOUR)}
            </TimeSelectorWrapper>
            <div className='time-selector-separator'>
              <span style={{ marginTop: '30px' }} />
              <span />
            </div>
            <TimeSelectorWrapper>
              <TimeSpan>Minutos</TimeSpan>
              <TimeSelector
                type='text'
                inputMode="numeric"
                onChange={handleChangeMinuteSelectorValue}
                onClick={() => handleOpenSelector(ListTypes.MINUTE)}
                onBlur={() => handleMinuteSelectorBlur()}
                value={selectedMinute}
                placeholder='00'
              />
              {renderTimeSelector(ListTypes.MINUTE)}
            </TimeSelectorWrapper>
          </TimeSelectorContainer>
          <div className="buttons-container">
            {(!!selectedHour.length || !!selectedMinute.length) &&
            (
              <button 
                className="clean-button" 
                type='button' 
                onClick={handleClear}
              > Limpar
              </button>
            )}
            <Button
              className="confirm-button"
              title='Confirmar'
              onClick={handleConfirmChangeHourAndMinute}
            />
          </div>
        </TimePickerContainer>
      ) : null}
    </TimeWrapper>
  );
};
const PerguntaHorario = forwardRef(PerguntaHorarioReferenciable);
export { PerguntaHorario };
