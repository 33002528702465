import { CircleDot, SquareCheck } from 'components/Icons';
import { Alternativa, TipoPergunta } from 'core/pesquisa/Pesquisa';
import { AlternativaSelecionada } from 'core/pesquisa/resposta/pergunta/aternativa/RespostaAlternativa';
import { useInputError } from 'hooks/useInputError';
import { useResizableTextArea } from 'hooks/useResizableTextArea';
import React, {
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from 'react';
import { InputErrors } from 'sections/Question';
import { AlternativaAbertaNumero } from './AbertaNumero';
import { CheckButton, Container, InputErrorMsg, TextArea, TextAreaContainer, Wrapper } from './styles';

interface Props {
  alternativa: Alternativa;
  onChange: (checked: boolean) => void;
  onChangeTexto: (value: string, alternativa: AlternativaSelecionada) => void;
  checked: boolean;
  radio?: boolean;
  placeholder?: string;
  abertaNumero: boolean;
  valorAnterior?: string | number;
  errors?: Array<string>;
  tipoPergunta: TipoPergunta;
  disabled?: boolean;
}

const CHECKBOX_WIDTH = 32;

const AlternativaAberta: React.FC<Props> = ({
  alternativa,
  onChange,
  onChangeTexto,
  checked,
  disabled,
  radio = false,
  placeholder = alternativa.enunciado ?? 'Outro',
  abertaNumero = false,
  tipoPergunta,
  valorAnterior,
  errors,
}: Props) => {
  const [valorAlternativa, setValorAlternativa] = useState('');
  // estado criado para impedir a persistência dos dados após o usuário voltar para questão anterior
  const [persistenceValue, setPersistenceValue] = useState<boolean>(true);
  // estado criado para escutar qualquer alteração no checkbox
  const [listenChange, setListenChange] = useState<boolean>(false);
  const textRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const containerRef = useRef() as MutableRefObject<HTMLElement>;

  const [controlDimensions] = useResizableTextArea(textRef, valorAlternativa, containerRef, CHECKBOX_WIDTH);
  const [inputError, validate] = useInputError(); // hook para controle dos inputErrors

  const checktype = {
    [TipoPergunta.MULTIPLA]: '4px',
    [TipoPergunta.UNICA]: '50%',
  };

  useEffect(() => {
    if (!abertaNumero) {
      textRef.current.focus(); // da foco no textarea
    }
  }, [checked, abertaNumero]);

  useEffect(() => {
    if (!checked) {
      setValorAlternativa('');
    }
  }, [checked]);

  useEffect(() => {
    if (!checked) {
      validate('', InputErrors.TEXTO_MAX_LENGTH);
    }
  });

  useEffect(() => {
    if (valorAnterior !== undefined) {
      // verifica se o input checkbox está true se o value deve ser descartado e se houve mudança no checkbox
      if (checked && !persistenceValue && listenChange) {
        console.log('cleared');
        setValorAlternativa('');
      }
      // verifica se o input checkbox está true e se o value deve ser persistido
      if (checked && persistenceValue) {
        setValorAlternativa(String(valorAnterior));
        setPersistenceValue(false);
      }
    }
  }, [checked, listenChange, persistenceValue, valorAnterior]);

  const handleChange = ev => {
    setListenChange(true);
    if (onChange) onChange({ ...ev, checked: !checked, value: alternativa.id });
  };

  /**
   * Faz o mesmo que "handleChange", porém separado para lidar apenas com a alternativa
   * do tipo aberta numero.
   */
  const handleChangeNumberFocus = ev => {
    if (onChange) {
      onChange({ ...ev, checked: !checked, value: alternativa.id });
    }
  };

  /**
   * Limpa o valor atual da alternativa aberta numero no evento de blur caso
   * seu valor no momento do evento seja vazio
   */
  const handleNumberInputBlur = (event, value: string) => {
    // Caso o valor seja vazio, será setado "checked" como falso para o input ser resetado.
    if (onChange && value === '') {
      onChange({ ...event, value: alternativa.id, checked: false });
    }
  };
  
  /**
   * Faz o mesmo que "handleChangeText", porém, adaptado para lidar apenas com a alternativa
   * do tipo aberta numero.
   */
  const handleChangeNumber = (newValue: string) => {
    if (onChangeTexto) {
      const re = /^[0-9\b]+$/;
      if (newValue === '' || re.test(newValue)) {
        setValorAlternativa(newValue);
        onChangeTexto(newValue, {
          id_alternativa: alternativa.id,
          ordem_selecao: alternativa.ordem,
          tipo_alternativa: alternativa.tipo_alternativa,
          comportamento: alternativa.comportamento,
        });
      }
    }
  };

  const handleChangeText = (
    element: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const answer = element.target.value;
    if (!answer.trim().length) {
      setTimeout(() => {
        return setValorAlternativa('');
      }, 100);
    }

    setValorAlternativa(element.target.value);
    validate(element.target.value, InputErrors.TEXTO_MAX_LENGTH);

    if (onChangeTexto) {
      onChangeTexto(element.target.value, {
        id_alternativa: alternativa.id,
        ordem_selecao: alternativa.ordem,
        tipo_alternativa: alternativa.tipo_alternativa,
        comportamento: alternativa.comportamento,
      });
    }
  };

  const preventsLineBreak = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const renderCheckIcon = () => {
    if (checktype[tipoPergunta] === '4px') {
      return <SquareCheck className='selected-icon' />;
    }
    return <CircleDot className='selected-icon' />;
  };

  return !abertaNumero ? (
    <Container ref={containerRef}>
      <Wrapper>
        <CheckButton
          checked={checked}
          rounded={checktype[tipoPergunta]}
          role="button"
          aria-hidden="true"
          onClick={e => handleChange(e)}
        >
          { renderCheckIcon() }
        </CheckButton>
        <TextAreaContainer
          inputError={inputError}
        >
          {controlDimensions(
            { minWidth: '250px', minHeight: '40px', padding: '8px' }
          )}
          <TextArea
            maxLength={InputErrors.TEXTO_MAX_LENGTH + 1}
            ref={textRef}
            id={`${alternativa.id}`}
            placeholder={placeholder}
            disabled={!checked}
            onChange={handleChangeText}
            value={valorAlternativa}
            onKeyDown={e => preventsLineBreak(e)}
          />
        </TextAreaContainer>
      </Wrapper>
      {inputError && 
        <InputErrorMsg>Limite de { InputErrors.TEXTO_MAX_LENGTH } caracteres</InputErrorMsg> }
    </Container>
  ) : (
    <div>
      <AlternativaAbertaNumero
        initialValue={valorAlternativa}
        alternativeId={alternativa.id}
        placeholder={placeholder}
        onChangeNumber={handleChangeNumber}
        onFocus={handleChangeNumberFocus}
        onBlur={handleNumberInputBlur}
        errors={errors}
        disabled={!!disabled && !checked}
        checked={checked}
      />
    </div>
  );
};

export { AlternativaAberta };
